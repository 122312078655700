import Utils from '../../utility'
import { clearCustomerDetails } from './reducer'
export const getProducts = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    const { order, sortField, offset, search } = getState().productsReducer
    Utils.api.getApiCall(
      Utils.endPoints.storeStock,
      `/1?order=${order}&sortField=${sortField}&offset=${offset}&take=${50}&search=${search}`,
      (respData: any) => {
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: {
            products: respData.data?.storeStocks,
            count: respData?.data?.count,
          },
        })
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        let { data } = error
        if (data?.message === '' || !data) {
          Utils.showAlert(2, 'Store Stocks Get Error:')
        } else {
          Utils.showAlert(2, 'Store Stocks Get Error: ' + data?.message)
        }
      },
    )
  }
}
//customer pin api
export const CustomerPin = (id: any, setFiledValue: any) => {
  return (dispatch: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    Utils.api.getApiCall(
      Utils.endPoints.customerPin,
      `?pin=${id}`,
      (respData: any) => {
        setFiledValue('customerName', respData.data?.name)
        setFiledValue('phoneNumber', respData.data?.phoneNumber)

        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: {
            pin: respData.data,
          },
        })
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        let { data } = error
        if (data?.message === '' || !data) {
          Utils.showAlert(2, 'Customer By Pin Get Error:')
        } else {
          Utils.showAlert(2, 'Customer By Pin Get Error: ' + data?.message)
        }
      },
    )
  }
}

export const creatOrder = (
  test: any,
  products: any,
  totalAndDiscounts: any,
  setOpen: any,
  open?: any,
  onlinePinValue?: any,
  setAddedProduct?: any,
  storeId?: any,
  setBarcode?: any,
  setIsDisabled?: any,
  setDiscountPercentage?: any,
  setDiscountAmount?: any,
  setButtonClicked?: any,
  StoreID?: any,
  setCashReceive?:any,
  setCashReturn?:any,
) => {
  return (dispatch: any, getState: any) => {
    const { storeId } = getState().commonReducer
    const {customerDetails,customerName,customerNumber}:any = getState().productsReducer

    setButtonClicked(true)
    setIsDisabled(true)
    const { grandTotal, discounPersentage, discounAmount } = totalAndDiscounts
    let dataToSend: any = {}
    products.forEach((item: any) => {
      item.quantity > 0 &&
        (dataToSend = {
          product: products.map((item: any) => {
            return {
              product: item.id,
              quantity: item.quantity,
              sellingPrice: item.sellingPrice,
            }
          }),
          customer: {
            customerName: customerDetails.name || test.customerName || customerName,
            customerPhoneNumber:
              test.customerNumber === '' ? null : customerDetails.number || test.customerNumber || customerNumber,
            gst: customerDetails.gst || test.customerGst,
            email: test.email,
            companyName: customerDetails.company || test.customerCompany,
            newAddress: test.address,
          },
          paymentMethod: open ? open : '',
          store: parseInt(storeId || StoreID),
          age: test.ageGroup ? test.ageGroup : '',
          notes: '',
          gender: test.gender ? test.gender : '',
          orderAmountTotal: grandTotal,
          discountAmount: discounAmount === '' ? 0 : discounAmount,
          discountPercent: discounPersentage === '' ? 0 : discounPersentage,
          // eslint-disable-next-line
          orderType:
            (test?.payType === 'swiggy' && 'swiggy') ||
            (test?.payType === 'zomato' && 'zomato') ||
            (test?.payType === 'ondc' && 'ondc') ||
            // (values?.payType === 'paytm' && 'paytm') ||
            'pos',
          walletUsed:
            onlinePinValue && !discounAmount && !discounPersentage
              ? onlinePinValue
              : 0,
        })
    })

    if (!dataToSend.gender) {
      delete dataToSend.gender
    }
    if (!dataToSend.age) {
      delete dataToSend.age
    }
    if (!dataToSend.notes) {
      delete dataToSend.notes
    }
    if (!dataToSend.paymentMethod) {
      delete dataToSend.paymentMethod
    }
    Utils.api.postApiCall(
      Utils.endPoints.orderCreate,
      dataToSend,
      (respData: any) => {
      dispatch(clearCustomerDetails())
        Utils.showAlert(1, 'Success')
        setOpen('')
        dispatch(orderInvoice(respData.data.id))
        setAddedProduct([])
        setBarcode('')
        setDiscountPercentage(0)
        setDiscountAmount(0)
        setIsDisabled(false)
        setButtonClicked(false)
        setCashReturn("")
        setCashReceive("")
      },
      (error: any) => {
        let { data } = error
        // eslint-disable-next-line
        if (
          data?.message == 'customerPhoneNumber must be a valid phone number'
        ) {
          // eslint-disable-next-line
          Utils.showAlert(
            2,
            'Enter Valid Phone Number ' +
              data?.message +
              '.' +
              ' It should be of 10 digits',
          )
          setIsDisabled(true)
        } else if (data?.message === '' || !data) {
          Utils.showAlert(2, 'Orders Add Error')
          setIsDisabled(true)
        } else {
          Utils.showAlert(2, 'Orders Add Error: ' + data?.message)
          setIsDisabled(true)
        }
        // setAddedProduct([])
        setBarcode('')
      },
    )
  }
}

export const orderInvoice = (id: any) => {
  return (dispatch: any, getState: any) => {
    const { storeId } = getState().commonReducer

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    Utils.api.getApiCall(
      Utils.endPoints.orderInvoice + storeId + "/" + id,
      '',
      (respData: any) => {
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: {
            invoiceHtml: respData.data,
        },
        });

        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        dispatch(getProductsBackendSearch())
      },
      (error: any) => {
        let { data } = error
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        if (data?.message === '' || !data) {
          Utils.showAlert(2, 'Invoice Get Error')
        } else {
          Utils.showAlert(
            2,
            'Invoice Get Error: ' + data?.error || data?.message,
          )
        }
      },
    )
  }
}
export const getProductsBackendSearch: any = (
  billerIdData: any,
  barcode?: any,
) => {
  return (dispatch: any, getState: any) => {
    // const StoreID = localStorage.getItem('storeId')
    const { storeId: StoreID } = getState().commonReducer
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    const { order, sortField, offset } = getState().productsReducer
    Utils.api.getApiCall(
      Utils.endPoints.storeStock,
      `/${StoreID}?order=${order}&sortField=${sortField}&offset=${offset}&take=${15}&search=${barcode}`,
      (respData: any) => {
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: {
            products: respData.data?.storeStocks,
            // count: respData?.data?.count,
          },
        })
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        let { data } = error
        if (data?.message === '' || !data) {
          Utils.showAlert(2, 'Store Stocks Get Error:')
        } else {
          Utils.showAlert(2, 'Store Stocks Get Error: ' + data?.message)
        }
      },
    )
  }
}

export const getCustomernamebynumber: any = (
  Phonenumber: any,
  setFiledValue: any,
  setIsDisabled: any,
) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })

    Utils.api.getApiCall(
      Utils.endPoints.customerName,
      `getCustomerFromPhoneNumber?phoneNumber=${Phonenumber}`,
      (respData: any) => {      
        setIsDisabled(false)
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: {
            customerName: respData.data.name.toString(),
            customerNumber:  respData.data.phoneNumber.toString(),
            customerCompany: respData.data.companyName,
            customerGst: respData.data.GSTIN,
            loading: false,
          },
        })
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
      },
      (error: any) => {
        // let { data } = error
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: {customerName:  '',loading: false },
        })
        //   if(data?.message === "" || !data) {
        //     Utils.showAlert(2,"Customers Name Get Error:")
        // }else{
        //     Utils.showAlert(2,"Customers Name Get Error: " + data?.message)
        // }
      },
    )
  }
}

// Create an action creator
export const updateCustomerDetails = (details) => {
  return (dispatch: any, getState: any) => {
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: {
            customerDetails:details
          }
  });
  };
}
